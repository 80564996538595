<template>
    <div class="quote-container">
        <v-overlay
                :opacity="0.15"
                :value="loader"
                absolute
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-card elevation="6" class="mx-auto">
            <v-card-text>
                <div class="black--text darken-1 text-center label">{{ '" ' + quote + '"' }}</div>
                <div class="black--text darken-1 text-center">{{ person }}</div>
                <div class="error-container" v-if="loadingError">
                    <v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {api} from "Api";

export default {
    name: "MotivationalQuote",
    data() {
        return {
            errorMessage: null,
            loader: true,
            loadingError: false,
            quote: '',
            person: '',
        }
    },
    methods: {
        getValues() {
            return new Promise((resolve, reject) => {
                const url = '/sparkline/card/gm-motivational-quote'
                if(url) {
                    api
                        .get(url)
                        .then((response) => {
                            const data = response.data.data
                            resolve(data)
                        })
                        .catch((error) => {
                            reject(error)
                        })
                } else {
                    reject('error')
                }
            })
        },
    },
    mounted() {
        this.getValues()
            .then((data) => {
                this.quote = data.quote
                this.person = data.person
                this.loader = false
            })
            .catch((error) => {
                this.loader = false
                this.loadingError = true
                this.errorMessage = error
            })
    }
}
</script>

<style scoped>
.label {
    font-weight: bold;
    font-size: 1.25em !important;
}
</style>